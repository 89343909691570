import React from "react"
import { Box, ResponsiveContext } from "grommet"
import Section from "../Section"
import ValuesCard from "./ValuesCard"
import { Target, Directions, Group, Shield, Cycle } from "grommet-icons"

const Values = () => {
  return (
    <Section
      //   height="large"
      background="background"
      color="primary"
      heading="OS NOSSOS VALORES"
      id="values"
    >
      <ResponsiveContext.Consumer>
        {size =>
          size === "small" ? (
            <Box direction="column" justify="center" wrap>
              <ValuesCard
                heading="Liderança"
                text="Gerar impacto real na atividade do cliente proporcionando resultados
              tangíveis e personalizados."
                icon={<Group size="large" />}
              />
              <ValuesCard
                heading="Flexibilidade"
                text=" Aumentar a proximidade com o cliente, diferenciando-nos dos nossos
              concorrentes e investir permanentemente em inovação, capacitação e
              resiliência."
                icon={<Directions size="large" />}
              />
              <ValuesCard
                heading="Foco"
                text="Projetar o progresso numa direção clara, o que é crucial na
              especialização, competência e produtividade."
                icon={<Target size="large" />}
              />
              <ValuesCard
                heading="Fiabilidade"
                text=" Construir confiança e relacionamentos de longo prazo com base na
              experiência e excelência do nosso trabalho."
                icon={<Shield size="large" />}
              />
              <ValuesCard
                heading="Legado"
                text="Resgatar a tradição, unindo-a com as tecnologias do futuro."
                icon={<Cycle size="large" />}
              />
            </Box>
          ) : (
            <Box direction="row" justify="center">
              <ValuesCard
                heading="Liderança"
                text="Gerar impacto real na atividade do cliente proporcionando resultados
              tangíveis e personalizados."
                icon={<Group size="xlarge" />}
              />
              <ValuesCard
                heading="Flexibilidade"
                text=" Aumentar a proximidade com o cliente, diferenciando-nos dos nossos
              concorrentes e investir permanentemente em inovação, capacitação e
              resiliência."
                icon={<Directions size="xlarge" />}
              />
              <ValuesCard
                heading="Foco"
                text="Projetar o progresso numa direção clara, o que é crucial na
              especialização, competência e produtividade."
                icon={<Target size="xlarge" />}
              />
              <ValuesCard
                heading="Fiabilidade"
                text=" Construir confiança e relacionamentos de longo prazo com base na
              experiência e excelência do nosso trabalho."
                icon={<Shield size="xlarge" />}
              />
              <ValuesCard
                heading="Legado"
                text="Resgatar a tradição, unindo-a com as tecnologias do futuro."
                icon={<Cycle size="xlarge" />}
              />
            </Box>
          )
        }
      </ResponsiveContext.Consumer>
    </Section>
  )
}

export default Values
