import React from "react"
import { Text, Paragraph } from "grommet"
import Section from "../Section"

const IntroAbout = () => {
  return (
    <Section
      id="about"
      background="brand"
      color="text"
      heading="AS NOSSAS RAÍZES"
    >
      <Paragraph fill textAlign="start">
        A ideia que viria a dar origem à{" "}
        <Text weight="bold">Mons Consulting</Text> foi plantada na primeira
        semana de 2020, quando, após uma visita dos fundadores à Serra da
        Estrela, reparou-se no potencial paisagístico e turístico, especialmente
        ecológico daquela serra em processo de desenvolvimento.
      </Paragraph>
      <Paragraph fill textAlign="start">
        Após 6 meses e explorações e contactos feitos, num vale da Serra da
        Estrela, foram dados os primeiros passos para criação de uma empresa
        para atender ao desenvolvimento sustentável e serviços ambientais do
        interior rural.
      </Paragraph>
      <Paragraph fill textAlign="start">
        Em Setembro de 2020 a <Text weight="bold">Mons Consulting</Text> foi
        formada.
      </Paragraph>
    </Section>
  )
}

export default IntroAbout
