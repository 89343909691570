import React from "react"
import {
  Box,
  ResponsiveContext,
  Menu,
  Nav,
  Text,
  Header as GrommetHeader,
} from "grommet"
import { Menu as MenuIcon } from "grommet-icons"
import styled from "styled-components"
import AnchorLink from "react-anchor-link-smooth-scroll"
import { StaticImage } from "gatsby-plugin-image"
import Link from "../Link"

const StyledAnchorLink = styled(AnchorLink)`
  color: #e5e8eb;
`
const StyledLink = styled(Link)`
  color: #e5e8eb;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`

export const HeaderAbout = () => {
  return (
    <Box elevation="small">
      <GrommetHeader background="brand">
        <Box
          direction="row"
          align="center"
          justify="between"
          background={{
            color: "button",
          }}
          fill="horizontal"
          pad={{ horizontal: "medium", vertical: "small" }}
        >
          <Box align="center" justify="start">
            <Link to="/">
              <StaticImage
                src="../../assets/mons_logo.png"
                alt="Mons logo"
                placeholder="tracedSVG"
                layout="constrained"
                height={45}
              />
            </Link>
          </Box>
          <ResponsiveContext.Consumer>
            {size =>
              size === "small" ? (
                <Box justify="end" pad="medium">
                  <Menu
                    a11yTitle="Navigation Menu"
                    dropProps={{ align: { top: "bottom", right: "right" } }}
                    icon={<MenuIcon color="selected-text" />}
                    items={[
                      {
                        label: "Início",
                        href: "/",
                      },
                      {
                        label: "Quem Somos",
                        href: "#about",
                      },
                      {
                        label: "O Que Fazemos",
                        href: "#mission",
                      },
                      {
                        label: "Os Nossos Valores",
                        href: "#contact",
                      },
                    ]}
                  />
                </Box>
              ) : (
                <Nav
                  align="center"
                  flex={false}
                  direction="row"
                  justify="center"
                  gap="medium"
                  pad="small"
                >
                  <StyledLink to="/">
                    <Text color="text" weight="normal">
                      Início
                    </Text>
                  </StyledLink>
                  <StyledAnchorLink href="#about" color="background">
                    <Text>Quem Somos</Text>
                  </StyledAnchorLink>
                  <StyledAnchorLink href="#mission" color="background">
                    <Text>O Que Fazemos</Text>
                  </StyledAnchorLink>
                  <StyledAnchorLink href="#values" color="background">
                    <Text>Os Nossos Valores</Text>
                  </StyledAnchorLink>
                </Nav>
              )
            }
          </ResponsiveContext.Consumer>
        </Box>
      </GrommetHeader>
    </Box>
  )
}

export default HeaderAbout
