import React from "react"
import { Box } from "grommet"
import Section from "../Section"
import ServiceCard from "./ServiceCard"

const Mission = ({ data }) => {
  // console.log("mission", data)
  return (
    <Box id="mission">
      <Section background="brand" color="text" heading="O QUE FAZEMOS">
        <Box direction="row" wrap justify="center" fill="horizontal">
          <ServiceCard
            heading="Soluções"
            text="efetivas e oportunas são concebidas
        e disponibilizadas ao cliente para uma eficaz e permanente gestão
        integrada dos recursos humanos, das tecnologias e dos recursos materiais
        necessários à produção dos bens e serviços a prestar."
            imgSrc={
              data.solutions.edges[0].node.childImageSharp.gatsbyImageData
                .images.fallback.src
            }
          />
          <ServiceCard
            heading="Responsabilidade Ambiental"
            text=" reduzindo o desperdício, aplicando processos produtivos e logísticos menos poluentes e práticas mais responsáveis e sustentáveis do ponto de vista ambiental e social, contribuindo assim para um futuro sustentável e próspero."
            imgSrc={
              data.resp_env.edges[0].node.childImageSharp.gatsbyImageData.images
                .fallback.src
            }
          />
          <ServiceCard
            heading="Responsabilidade Social"
            text=" por meio da qual a
          entidade participa diretamente na solução e superação das questões de
          fundo que afetam a qualidade de vida da comunidade. Indiretamente, o
          envolvimento dos colaboradores na vida e evolução da comunidade local,
          também contribui para um progresso comunitário substancial necessários à
          produção dos bens e serviços a prestar."
            imgSrc={
              data.resp_soc.edges[0].node.childImageSharp.gatsbyImageData.images
                .fallback.src
            }
          />
          <ServiceCard
            heading="Sinergias "
            text="que estabelecem relações entre projetos e clientes, de forma a gerar novas soluções ou serviços inovadores, éticos e sustentáveis."
            imgSrc={
              data.synergy.edges[0].node.childImageSharp.gatsbyImageData.images
                .fallback.src
            }
          />
          <ServiceCard
            heading="Formação e Capacitação"
            text="constante dos recursos
          humanos para o desenvolvimento profissional, o empreendedorismo, a
          inovação e a responsabilidade social."
            imgSrc={
              data.training.edges[0].node.childImageSharp.gatsbyImageData.images
                .fallback.src
            }
          />
          <ServiceCard
            heading="Investigação Contínua"
            text="alinhada primariamente
          com o Plano de Recuperação Económica de Portugal 2020|2030 e em segunda
          linha atende às Diretivas Comunitárias e ainda aos Objetivos de
          Desenvolvimento Sustentável definidos pelas Nações Unidas."
            imgSrc={
              data.research.edges[0].node.childImageSharp.gatsbyImageData.images
                .fallback.src
            }
          />
        </Box>
        {/* <Paragraph fill textAlign="left">
        <Text weight="bold">Formação e Capacitação</Text> constante dos recursos
        humanos para o desenvolvimento profissional, o empreendedorismo, a
        inovação e a responsabilidade social.
      </Paragraph>
      <Paragraph fill textAlign="left">
        <Text weight="bold">Investigação Contínua</Text> alinhada primariamente
        com o Plano de Recuperação Económica de Portugal 2020|2030 e em segunda
        linha atende às Diretivas Comunitárias e ainda aos Objetivos de
        Desenvolvimento Sustentável definidos pelas Nações Unidas.
      </Paragraph>
      <Paragraph fill textAlign="left">
        <Text weight="bold">Sinergias</Text> que estabelecem relações entre
        projetos e clientes, de forma a gerar novas soluções ou serviços
        inovadores, éticos e sustentáveis.
      </Paragraph>
      <Paragraph fill textAlign="left">
        <Text weight="bold">Soluções</Text> efetivas e oportunas são concebidas
        e disponibilizadas ao cliente para uma eficaz e permanente gestão
        integrada dos recursos humanos, das tecnologias e dos recursos materiais
        necessários à produção dos bens e serviços a prestar.
      </Paragraph>
      <Paragraph fill textAlign="left">
        <Text weight="bold">Responsabilidade Social</Text> por meio da qual a
        entidade participa diretamente na solução e superação das questões de
        fundo que afetam a qualidade de vida da comunidade. Indiretamente, o
        envolvimento dos colaboradores na vida e evolução da comunidade local,
        também contribui para um progresso comunitário substancial necessários à
        produção dos bens e serviços a prestar.
      </Paragraph>{" "}
      <Paragraph fill textAlign="left">
        <Text weight="bold">Responsabilidade Ambiental</Text> reduzindo o
        desperdício, aplicando processos produtivos e logísticos menos poluentes
        e práticas mais responsáveis e sustentáveis do ponto de vista ambiental
        e social, contribuindo assim para um futuro sustentável e próspero.
      </Paragraph> */}
      </Section>
    </Box>
  )
}

export default Mission
