import React from "react"
import { Text, Box, CardHeader, ResponsiveContext } from "grommet"

const ValuesCard = ({ icon, heading, text }) => {
  return (
    <ResponsiveContext.Consumer>
      {size =>
        size === "small" ? (
          <Box
            style={{
              height: "20vh",
            }}
            direction="row"
            // data-sal="fade"
            background="primary"
            margin="small"
            alignSelf="center"
            height="xsmall"
            align="center"
            justify="between"
          >
            <Box margin="medium">{icon}</Box>
            <Box width="medium" margin="medium">
              <CardHeader>
                <Text size="small" color="text" textAlign="start">
                  {heading}
                </Text>
              </CardHeader>
              <Box>
                <Text size="xsmall" color="text" textAlign="start">
                  {text}
                </Text>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box
            // style={{
            //   height: "47vh",
            // }}
            direction="column"
            // data-sal="fade"
            background="primary"
            pad="small"
            margin="small"
            alignSelf="center"
            width="small"
            height="medium"
            align="center"
          >
            <Box>{icon}</Box>
            <Box>
              <CardHeader justify="center" margin="xsmall">
                <Text
                  size={size === "small" ? "medium" : "large"}
                  color="text"
                  textAlign="center"
                >
                  {heading}
                </Text>
              </CardHeader>
              <Box fill="horizontal" wrap>
                <Text
                  size="small"
                  color="text"
                  margin="xsmall"
                  textAlign="start"
                >
                  {text}
                </Text>
              </Box>
            </Box>
          </Box>
        )
      }
    </ResponsiveContext.Consumer>
  )
}

export default ValuesCard
