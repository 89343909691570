import React from "react"
import { graphql } from "gatsby"
// import { GatsbyImage } from "gatsby-plugin-image"
import { Grommet } from "grommet"
import "@fontsource/readex-pro"
import { GlobalStyle, grommetTheme } from "../theme"
import { HeaderAbout } from "../components/header"
import IntroAbout from "../components/about/IntroAbout"
import Mission from "../components/about/Mission"
import Values from "../components/about/Values"
import { Footer } from "../components/footer"
import TeamAbout from "../components/about/TeamAbout"
import { ParallaxProvider, ParallaxBanner } from "react-scroll-parallax"

export const query = graphql`
  query MyQuery2 {
    parallax_1: allFile(
      filter: { relativePath: { eq: "backgrounds/bg_13.jpg" } }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }
      }
    }
    parallax_2: allFile(
      filter: { relativePath: { eq: "backgrounds/bg_10.jpg" } }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }
      }
    }
    andre: allFile(filter: { relativePath: { eq: "avatars/Untitled_2.png" } }) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }
      }
    }
    dito: allFile(filter: { relativePath: { eq: "avatars/dito.png" } }) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }
      }
    }

    solutions: allFile(
      filter: { relativePath: { eq: "cards/solutions.jpg" } }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }
      }
    }
    synergy: allFile(filter: { relativePath: { eq: "cards/synergy.jpg" } }) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }
      }
    }
    resp_soc: allFile(filter: { relativePath: { eq: "cards/resp_soc.jpg" } }) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }
      }
    }
    resp_env: allFile(filter: { relativePath: { eq: "cards/resp_env.jpg" } }) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }
      }
    }
    training: allFile(filter: { relativePath: { eq: "cards/training.jpg" } }) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }
      }
    }
    research: allFile(filter: { relativePath: { eq: "cards/research.jpg" } }) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`

const About = ({ data }) => {
  // console.log(data)
  return (
    <Grommet
      // @ts-ignore
      theme={grommetTheme}
    >
      <ParallaxProvider>
        {/* <SEO title="About" /> */}

        <GlobalStyle />
        <HeaderAbout />
        <ParallaxBanner
          layers={[
            {
              // image: "https://i.ibb.co/TMLbXnJ/mons-valley.jpg",
              image:
                data.parallax_1.edges[0].node.childImageSharp.gatsbyImageData
                  .images.fallback.src,
              amount: 0.2,
            },
          ]}
          style={{
            height: "50vh",
          }}
        />
        <IntroAbout />
        <TeamAbout data={data} />
        <ParallaxBanner
          layers={[
            {
              // image: "https://i.ibb.co/TMLbXnJ/mons-valley.jpg",
              image:
                data.parallax_2.edges[0].node.childImageSharp.gatsbyImageData
                  .images.fallback.src,
              amount: 0.2,
            },
          ]}
          style={{
            height: "70vh",
          }}
        />
        <Mission data={data} />
        <Values />
        <Footer />
      </ParallaxProvider>
    </Grommet>
  )
}
export default About
