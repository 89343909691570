import React from "react"
import { Text, Box, Card, CardHeader, Image } from "grommet"

const TeamMemberCard = ({ avatarPath = "", name, text, reverse = false }) => {
  return (
    <Card
      direction={reverse ? "row-reverse" : "row"}
      style={{
        // width: "90vh",
        // height: "40vh",
        borderRadius: "0",
        boxShadow: "none",
      }}
      // justify="start"
      // data-sal="fade"
      background="background"
      pad="small"
      elevation="medium"
      border={{ color: "accent", size: "medium" }}
      width="medium"
      height="small"
      margin="small"
      di
    >
      <Box basis="1/3">
        <Image
          // align="star"
          justify="center"
          // size="medium"
          src={avatarPath}
          // margin="small"
          fit="cover"
        />
      </Box>
      <Box justify="center" basis="2/3">
        <CardHeader
          align="center"
          justify={reverse ? "end" : "start"}
          margin="xsmall"
        >
          <Text size="large" color="accent">
            {name}
          </Text>
        </CardHeader>
        <Box>
          <Text
            size="small"
            color="accent"
            margin="xsmall"
            textAlign={reverse ? "end" : "start"}
            // truncate
          >
            {text}
          </Text>
        </Box>
      </Box>
    </Card>
  )
}

export default TeamMemberCard
