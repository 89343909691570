import React from "react"
import TeamMemberCard from "./TeamMemberCard"
import { Box, ResponsiveContext } from "grommet"

import Section from "../Section"

// <TeamMemberCard
//                 name="Carlos Santos"
//                 text="Gestor de Projetos e Eventos, com formação em Marketing e Publicidade."
//                 avatarPath={
//                   data.ponga.edges[0].node.childImageSharp.gatsbyImageData
//                     .images.fallback.src
//                 }
//               />
const TeamAbout = ({ data }) => {
  return (
    <Section background="background" color="accent" heading="A NOSSA EQUIPA">
      <ResponsiveContext.Consumer>
        {size =>
          size === "small" ? (
            <Box>
              <TeamMemberCard
                name="André Morais"
                text="Especializado em Engenharia e Tecnologias de Informação, apaixonado pela Natureza."
                avatarPath={
                  data.andre.edges[0].node.childImageSharp.gatsbyImageData
                    .images.fallback.src
                }
              />
              <TeamMemberCard
                name="Fernando Morais"
                text="Mentor da equipa, com experiência em Empreendedorismo, Biologia e Construção Civil. "
                avatarPath={
                  data.dito.edges[0].node.childImageSharp.gatsbyImageData.images
                    .fallback.src
                }
                reverse
              />
            </Box>
          ) : (
            <React.Fragment>
              <Box direction="row" justify="center">
                <TeamMemberCard
                  name="André Morais"
                  text="Especializado em Engenharia e Tecnologias de Informação, apaixonado pela Natureza."
                  avatarPath={
                    data.andre.edges[0].node.childImageSharp.gatsbyImageData
                      .images.fallback.src
                  }
                />
              </Box>
              <Box direction="row" justify="center">
                <TeamMemberCard
                  name="Fernando Morais"
                  text="Mentor da equipa, com experiência em Empreendedorismo, Biologia e Construção Civil. "
                  avatarPath={
                    data.dito.edges[0].node.childImageSharp.gatsbyImageData
                      .images.fallback.src
                  }
                  reverse
                />
              </Box>
            </React.Fragment>
          )
        }
      </ResponsiveContext.Consumer>
    </Section>
  )
}

export default TeamAbout
