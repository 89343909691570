import React from "react"
import { Text, Box, CardHeader, Image, ResponsiveContext } from "grommet"

const ServiceCard = ({ imgSrc, heading, text }) => {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          style={{
            height: `${size === "small" ? "70vh" : "60vh"}`,
          }}
          direction="column"
          data-sal="fade"
          background="primary"
          // pad="small"
          margin="small"
          alignSelf="center"
          width="medium"
        >
          <Box basis="small">
            <Image
              align="center"
              justify="center"
              size="small"
              src={imgSrc}
              // margin="xsmall"
              fit="cover"
            />
          </Box>
          <Box>
            <CardHeader>
              <Text
                size="large"
                color="text"
                margin={{ horizontal: "small", top: "xsmall" }}
              >
                {heading}
              </Text>
            </CardHeader>
            <Box fill="horizontal">
              <Text
                size="small"
                color="text"
                margin={{ horizontal: "small", top: "xsmall" }}
                textAlign="start"
              >
                {text}
              </Text>
            </Box>
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  )
}

export default ServiceCard
